import React from 'react';
import {
  Container,
  Grid,
  Header,
  Button,
  Image,
  Segment,
  Input,
  Loader,
  Progress,
  Modal,
  Icon
} from 'semantic-ui-react';
import axios from 'axios';
import crypto from 'crypto';
import './App.css';

const QUEUE_SIZE = 5;

const INITIAL_STATE = {
  queue: [],
  selectedScenery: -1,
  loading: false,
  password: '',
  authorized: false,
  availableTags: [],
  tags: [],
  addTag: false,
  newTag: '',
  loadingAddTag: false
};

class App extends React.Component {
  state = INITIAL_STATE;

  sceneries = ['Forest', 'Water', 'Field', 'Urban', 'Misc'];
  sceneryName = {
    Forest: 'Hidden sight',
    Water: 'Water',
    Field: 'Clear sight',
    Urban: 'Urban',
    Misc: 'Misc'
  };

  fetchImage = async () => {
    if (!this.state.queue.length) {
      this.setState({ loading: true });
    } else if (this.state.queue.length === QUEUE_SIZE) {
      return;
    }

    return axios
      .post(`https://us-central1-detecht-development.cloudfunctions.net/mtGetSceneryImage`, {
        password: crypto
          .createHash('sha256')
          .update(this.state.password.toLowerCase())
          .digest('hex')
      })
      .then(({ data: { id, url, notClassifiedBy, maxspeed } }) => {
        if (id) {
          const queue = notClassifiedBy.includes(this.state.password.toLowerCase())
            ? [...this.state.queue, { id, url, maxspeed }]
            : this.state.queue;
          if (queue.length <= QUEUE_SIZE)
            this.setState({ loading: false, queue, authorized: true }, () => {
              if (queue.length < QUEUE_SIZE && id) {
                this.fetchImage();
              }
            });
        } else {
          this.setState({ loading: false, authorized: false });
        }
      });
  };

  submit = async () => {
    const { queue, selectedScenery, password, tags, availableTags } = this.state;
    if (selectedScenery < 0 || !queue.length) {
      return;
    }
    const { id } = queue[0];
    const scenery = this.sceneries[selectedScenery].toLowerCase();
    this.setState(
      {
        ...INITIAL_STATE,
        availableTags,
        loading: queue.length <= 1,
        password,
        queue: queue.slice(1),
        selectedScenery: -1
      },
      async () => {
        await axios.post(`https://us-central1-detecht-development.cloudfunctions.net/mtSetLabel`, {
          password: crypto
            .createHash('sha256')
            .update(password)
            .digest('hex'),
          label: scenery,
          tags,
          id
        });

        this.fetchImage();
      }
    );
  };

  componentDidMount() {
    window.addEventListener('keypress', async ({ key }) => {
      if (['1', '2', '3', '4', '5'].includes(key)) {
        this.setState({ selectedScenery: Number(key) - 1 });
      } else if (key === 'Enter') {
        if (this.state.queue.length) {
          this.submit();
        } else {
          await this.fetchImage();
        }
      }
    });

    axios
      .get(`https://us-central1-detecht-development.cloudfunctions.net/mtGetTags`)
      .then(({ data: { tags: availableTags } }) => this.setState({ availableTags }));
  }

  render() {
    return (
      <Container
        id="container"
        style={{ marginTop: 30, background: '#171717', boxShadow: '0px 0px 50px 0px rgba(0,0,0,0.55)' }}
      >
        <Modal basic open={this.state.addTag} onClose={() => this.setState({ addTag: false })} size="mini">
          <Header icon="browser" content="Add tag" />
          <Modal.Content>
            <Input
              autoFocus
              fluid
              value={this.state.newTag}
              onChange={input => this.setState({ newTag: input.target.value })}
            />
          </Modal.Content>
          <Modal.Actions>
            {!this.state.loadingAddTag && (
              <Button
                onClick={() => {
                  this.setState({ newTag: '', addTag: false });
                }}
                inverted
              >
                <Icon name="cancel" /> Cancel
              </Button>
            )}
            <Button
              color="green"
              loading={this.state.loadingAddTag}
              disabled={this.state.newTag === ''}
              onClick={() => {
                this.setState({ loadingAddTag: true }, () => {
                  axios
                    .post(`https://us-central1-detecht-development.cloudfunctions.net/mtNewTag`, {
                      tag: this.state.newTag
                    })
                    .then(({ data: { tags: availableTags } }) =>
                      this.setState({ availableTags, addTag: false, newTag: '', loadingAddTag: false })
                    );
                });
              }}
            >
              <Icon name="checkmark" /> Add tag
            </Button>
          </Modal.Actions>
        </Modal>
        <Segment style={{ minHeight: 400, backgroundColor: '#222' }}>
          <Header as="h1" style={{ color: '#fff', fontWeight: '100' }}>
            Detecht Master Thesis - Image classification
          </Header>
          {this.state.loading ? (
            <Loader size="huge" active />
          ) : (
            <Grid>
              <Grid.Row>
                {this.state.queue[0] ? (
                  <>
                    <Grid.Column width={10} style={{ position: 'relative' }}>
                      <Image fluid src={this.state.queue[0].url} />
                      <Image
                        src={`maxspeed_${this.state.queue[0].maxspeed}.png`}
                        style={{
                          position: 'absolute',
                          top: 12,
                          left: 24,
                          zIndex: 100,
                          width: 60,
                          height: 60,
                          opacity: this.state.queue[0].maxspeed === 0 ? 0.5 : 1
                        }}
                      />
                      <Progress
                        value={this.state.queue.length}
                        total={QUEUE_SIZE}
                        inverted
                        progress={'value'}
                        color={this.state.queue.length < 2 ? 'red' : this.state.queue.length < 4 ? 'orange' : 'green'}
                        size="small"
                      ></Progress>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      {this.state.queue.length > 0 && (
                        <>
                          {this.sceneries.map((scenery, index) => (
                            <Button
                              key={scenery}
                              fluid
                              inverted
                              active
                              basic={index !== this.state.selectedScenery}
                              onClick={() => this.setState({ selectedScenery: index })}
                              style={{ textAlign: 'center', marginBottom: 16, fontSize: 16 }}
                            >
                              <Image
                                className="button-image"
                                style={{ width: 36, height: 36, marginRight: 24 }}
                                src={`./${scenery.toLowerCase()}.png`}
                              />
                              {this.sceneryName[scenery]}
                            </Button>
                          ))}
                          <Header style={{ color: '#fff' }}>Select suitable tags:</Header>
                          {this.state.availableTags.map((tag, index) => (
                            <Button
                              key={`tag-${index}`}
                              inverted
                              style={{ marginBottom: 4, color: '#000', background: '#fff' }}
                              color="black"
                              basic={!this.state.tags.includes(tag)}
                              active={this.state.tags.includes(tag)}
                              onClick={() => {
                                if (this.state.tags.includes(tag)) {
                                  this.setState({ tags: this.state.tags.filter(t => t !== tag) });
                                } else {
                                  this.setState({ tags: [...this.state.tags, tag] });
                                }
                              }}
                            >
                              {tag}
                            </Button>
                          ))}
                          <Button basic inverted onClick={() => this.setState({ addTag: true })}>
                            +
                          </Button>
                          <br />
                          <Button style={{ marginTop: 48 }} color="green" icon="save" fluid onClick={this.submit}>
                            Submit
                          </Button>
                        </>
                      )}
                    </Grid.Column>
                  </>
                ) : (
                  <Grid.Column width={16} textAlign="center">
                    <Input
                      style={{ marginTop: 100 }}
                      size="huge"
                      type="password"
                      value={this.state.password}
                      onChange={input => this.setState({ password: input.target.value })}
                      disabled={Boolean(this.state.authorized || this.state.queue[0])}
                      autoFocus
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          )}
        </Segment>
      </Container>
    );
  }
}

export default App;
